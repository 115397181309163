var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container-scroller"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"card-title text-white rounded p-3 mb-5 text-center",staticStyle:{"background-color":"#0b5d3f","box-shadow":"0 4px 6px rgba(0, 0, 0, 0.1)"}},[_vm._v(" Ajouter un produit ")]),_c('form',{staticClass:"forms-sample row",on:{"submit":function($event){$event.preventDefault();return _vm.addProductSpecification.apply(null, arguments)}}},[_c('div',{staticClass:"form-row col-md-12"},[_c('div',{staticClass:"form-group controls col-md-4"},[_vm._m(0),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeProductSelected),expression:"typeProductSelected"}],staticClass:"form-control mb-3",class:{ 
                    'form-control': true,
                    'is-invalid': _vm.formErrors.typeProductSelected
                  },attrs:{"id":"typeProduct","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.typeProductSelected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getCategories(_vm.typeProductSelected)}]}},[_c('option',{attrs:{"value":"null","selected":""}},[_vm._v("Selectionner un type ")]),_vm._l((_vm.typeProducts),function(typeProduct){return _c('option',{key:typeProduct.uuid,domProps:{"value":typeProduct.uuid}},[_vm._v(" "+_vm._s(typeProduct.name)+" ")])})],2),(_vm.formErrors.typeProductSelected)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.typeProductSelected)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group controls col-md-4"},[_vm._m(1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSpecification.category_id),expression:"productSpecification.category_id"}],staticClass:"form-control mb-3",class:{ 
                    'form-control': true,
                    'is-invalid': _vm.formErrors.category_id
                  },attrs:{"id":"category","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.productSpecification, "category_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","selected":""}},[_vm._v("Selectionner une catégorie ")]),_vm._l((_vm.categories),function(category){return _c('option',{key:category.uuid,domProps:{"value":category.uuid}},[_vm._v(" "+_vm._s(category.name)+" ")])})],2),(_vm.formErrors.category_id)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.category_id)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group controls col-md-4"},[_vm._m(2),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSpecification.supplier_id),expression:"productSpecification.supplier_id"}],staticClass:"form-control mb-3",class:{ 
                    'form-control': true,
                    'is-invalid': _vm.formErrors.supplier_id
                  },attrs:{"id":"supplier","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.productSpecification, "supplier_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","selected":""}},[_vm._v("Selectionner un fournisseur ")]),_vm._l((_vm.suppliers),function(supplier){return _c('option',{key:supplier.uuid,domProps:{"value":supplier.uuid}},[_vm._v(" "+_vm._s(supplier.name)+" ")])})],2),(_vm.formErrors.supplier_id)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.supplier_id)+" ")]):_vm._e()])]),_c('div',{staticClass:"form-row col-md-12"},[_c('div',{staticClass:"form-group controls col-md-6"},[_vm._m(3),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSpecification.product_id),expression:"productSpecification.product_id"}],staticClass:"form-control mb-3",class:{ 
                    'form-control': true,
                    'is-invalid': _vm.formErrors.product_id
                  },attrs:{"id":"product","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.productSpecification, "product_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","selected":""}},[_vm._v("Selectionner un produit ")]),_vm._l((_vm.products),function(product){return _c('option',{key:product.uuid,domProps:{"value":product.uuid}},[_vm._v(" "+_vm._s(product.name)+" ")])})],2),(_vm.formErrors.product_id)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.product_id)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group controls col-md-4"},[_vm._m(4),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSpecification.conditioning_unit),expression:"productSpecification.conditioning_unit"}],staticClass:"form-control mb-3",class:{ 
                    'form-control': true,
                    'is-invalid': _vm.formErrors.conditioning_unit
                  },attrs:{"id":"unitConditionnement","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.productSpecification, "conditioning_unit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","selected":""}},[_vm._v("Selectionner une unité de conditionnement ")]),_vm._l((_vm.unitOfConditionements),function(unitOfConditionement){return _c('option',{key:unitOfConditionement,domProps:{"value":unitOfConditionement}},[_vm._v(" "+_vm._s(unitOfConditionement)+" ")])})],2),(_vm.formErrors.conditioning_unit)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.conditioning_unit)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group controls col-md-2"},[_vm._m(5),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSpecification.sales_unit),expression:"productSpecification.sales_unit"}],staticClass:"form-control mb-3",class:{ 
                    'form-control': true,
                    'is-invalid': _vm.formErrors.sales_unit
                  },attrs:{"id":"unitSale","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.productSpecification, "sales_unit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","selected":""}},[_vm._v("Selectionner une unité de vente ")]),_vm._l((_vm.unitOfSaleOptions),function(unitOfSaleOption){return _c('option',{key:unitOfSaleOption,domProps:{"value":unitOfSaleOption}},[_vm._v(" "+_vm._s(unitOfSaleOption)+" ")])})],2),(_vm.formErrors.sales_unit)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.sales_unit)+" ")]):_vm._e()])]),_c('div',{staticClass:"form-row col-md-12"},[_c('div',{staticClass:"form-group controls col-md-7"},[_vm._m(6),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSpecification.brand),expression:"productSpecification.brand"}],staticClass:"form-control",class:{
                  'form-control': true,
                  'is-invalid': _vm.formErrors.brand,
                },attrs:{"type":"text","maxlength":"255","id":"brand","placeholder":"Entrez la marque","required":""},domProps:{"value":(_vm.productSpecification.brand)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.productSpecification, "brand", $event.target.value)},function($event){return _vm.clearError('brand')}]}}),(_vm.formErrors.brand)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.brand)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group controls col-md-3"},[_vm._m(7),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSpecification.unit),expression:"productSpecification.unit"}],staticClass:"form-control",class:{
                  'form-control': true,
                  'is-invalid': _vm.formErrors.unit,
                },attrs:{"type":"text","maxlength":"125","id":"unit","placeholder":"Entrez l'unité","required":""},domProps:{"value":(_vm.productSpecification.unit)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.productSpecification, "unit", $event.target.value)},function($event){return _vm.clearError('unit')}]}}),(_vm.formErrors.unit)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.unit)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group controls col-md-2"},[_vm._m(8),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSpecification.margin),expression:"productSpecification.margin"}],staticClass:"form-control",class:{
                  'form-control': true,
                  'is-invalid': _vm.formErrors.margin,
                },attrs:{"type":"number","min":"0","max":"100","id":"margin","placeholder":"Entrez la marge","required":""},domProps:{"value":(_vm.productSpecification.margin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.productSpecification, "margin", $event.target.value)},function($event){return _vm.clearError('margin')}]}}),(_vm.formErrors.margin)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.margin)+" ")]):_vm._e()])]),_c('div',{staticClass:"form-row col-md-12"},[_c('div',{staticClass:"form-group controls col-md-6"},[_vm._m(9),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSpecification.administration_channel),expression:"productSpecification.administration_channel"}],staticClass:"form-control",class:{
                  'form-control': true,
                  'is-invalid': _vm.formErrors.administration_channel,
                },attrs:{"type":"text","maxlength":"255","id":"administrationChannel","placeholder":"Entrez la voie d'administration","required":""},domProps:{"value":(_vm.productSpecification.administration_channel)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.productSpecification, "administration_channel", $event.target.value)},function($event){return _vm.clearError('administrationChannel')}]}}),(_vm.formErrors.administration_channel)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.administration_channel)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group controls col-md-6"},[_vm._m(10),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSpecification.dosage),expression:"productSpecification.dosage"}],staticClass:"form-control",class:{
                  'form-control': true,
                  'is-invalid': _vm.formErrors.dosage,
                },attrs:{"type":"text","maxlength":"255","id":"dosage","placeholder":"Entrez le dosage","required":""},domProps:{"value":(_vm.productSpecification.dosage)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.productSpecification, "dosage", $event.target.value)},function($event){return _vm.clearError('unit')}]}}),(_vm.formErrors.dosage)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.dosage)+" ")]):_vm._e()])]),_c('div',{staticClass:"d-flex justify-content-between col-12 flex-row"},[_c('button',{staticClass:"btn btn-danger btn-medpay-gray",on:{"click":_vm.cancel}},[_vm._v("Annuler")]),_c('button',{staticClass:"btn btn-success btn-medpay-green mr-2",attrs:{"type":"submit"}},[_vm._v(" Ajouter ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"typeProduct"}},[_vm._v("Type"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"category"}},[_vm._v("Categorie"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"supplier"}},[_vm._v("Fournisseur"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"product"}},[_vm._v("Nom"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"unitSale"}},[_vm._v("Unité de conditionnement "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"unitSale"}},[_vm._v("Unité de vente"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"brand"}},[_vm._v("Marque"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"unit"}},[_vm._v("Unité"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"margin"}},[_vm._v("Marge (%)"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"administrationChannel"}},[_vm._v("Voie d'administration"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"dosage"}},[_vm._v("Dosage"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
}]

export { render, staticRenderFns }